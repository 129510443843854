.worksite-loader {
    padding-left: 2px;
}

.worksites-table .placeholder-content_item1 {
    max-width: 14px;
}
.worksites-table .placeholder-content_item2 {
    max-width: 14px;
}
.worksites-table .placeholder-content_item3 {
    max-width: 200px;
}
.worksites-table .placeholder-content_item4 {
    max-width: 80px;
}
.worksites-table .placeholder-content_item5 {
    max-width: 140px;
}
.worksites-table .placeholder-content_item6 {
    max-width: 80px;
}
.worksites-table .placeholder-content_item7 {
    max-width: 100px;
}
.worksites-table .placeholder-content_item8 {
    max-width: 14px;
}
.worksites-table .placeholder-content_item9 {
    max-width: 40px;
}

.worksites-table .header1 {
    width: 6%;
}
.worksites-table .header2 {
    width: 6%;
}
.worksites-table .header3 {
    width: 20%;
}
.worksites-table .header4 {
    width: 10%;
}
.worksites-table .header5 {
    width: 18%;
}
.worksites-table .header6 {
    width: 10%;
}
.worksites-table .header7 {
    width: 20%;
}
.worksites-table .header8 {
    width: 3%;
}
.worksites-table .header9 {
    width: 7%;
}