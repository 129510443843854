.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.modal-cancel-button {
    background-color: #FFF;
    border: 0.5px solid #ff7a59;
    border-radius: 3px;
    color: #ff7a59;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
}

.modal-cancel-button:hover {
    background-color: #fff8f6;
}

.modal-continue-button {
    margin-left: 12px;
    background-color: #ff7a59;
    border: 0.5px solid #ff7a59;
    border-radius: 3px;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
}

.modal-continue-button:hover {
    background-color: #ff8f73;
}
