.custom-table {
    border: none;
    width: 100%;
    border-collapse: collapse;
    font-size: 0.8rem;
}

.custom-table thead th {
    border: 1px solid #bebebe;
    text-align: left;
    padding: 5px;
    font-weight: bold;
    background-color: rgb(65, 145, 195);
    color: white;
}

.custom-table tr.empty-row {
    height: 30px;
}

.custom-table tr.clickable {
    cursor: pointer;
}

.custom-table tr td {
    border: 1px solid #bebebe;
    text-align: left;
    padding: 5px;
}

.custom-table tr:nth-child(odd) {
    background-color: white;
}

.custom-table tr:nth-child(even) {
    background-color: #dce4ec;
}

.custom-table tr.selected {
    background-color: #ff7a59;
    color: white;
}

.custom-table + .MuiPagination-root {
    padding-top: 10px;
    display: flex;
}

.custom-table + .MuiPagination-root .MuiPagination-ul {
    margin-left: auto;
}

.custom-table + .MuiPagination-root .MuiPagination-ul .MuiPaginationItem-outlinedSecondary.Mui-selected {
    color: white;
    border: 1px solid navy;
    background-color: rgb(65, 145, 195);
}

.placeholder-content_item {
    width: 100%;
    height: 15px;
    margin: 2px 0px;
    background: #f6f7f8;
    z-index: 2;
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
    background-size: 1300px;
    overflow: hidden;
}

.placeholder-content_item:after, .placeholder-content_item:before {
    width: inherit;
    height: inherit;
    content: '';
}

@keyframes placeholderAnimate {
    0% {
        background-position: -650px 0;
   }
    100% {
        background-position: 650px 0;
   }
}