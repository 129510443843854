.filter-component {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.filter-object {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.filter-wrapper {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter {
    display: inline-block;
    padding: 10px 15px;
    font-weight: 400;
    background: #FFF;
    border: 0.5px solid #dce4ec;
    outline: 0;
    width: 200px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.filter::-webkit-input-placeholder {
    color: #aaa;
    text-indent: 0;
    font-weight: 300;
}

select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    appearance: none;
    outline-width: 0;
    display: block;
    margin: 0;
    outline: none;
    width: 232px !important;
    cursor: pointer;
    padding: 10px 15px;
    font-weight: 400;
    border: 0.5px solid #dce4ec;
    outline: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.filter-label {
    display: inline-block;
    top: 0;
    left: 0;
    padding: 11px 15px;
    background-color: #dce4ec;
    color: #2F404F;
    font-weight: 400;
    font-size: 13px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    width: 120px;
    text-align: center;
}

.filter:focus, .filter:active {
    text-indent: 0;
    background: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

input:hover, input:active, input:focus {
    border: 1px solid grey;
}

.filter:focus::-webkit-input-placeholder, .filter:active::-webkit-input-placeholder {
    color: #aaa;
}

.search-button {
    background-color: #FFF;
    border: 0.5px solid #ff7a59;
    border-radius: 3px;
    color: #ff7a59;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
}

.search-button:hover {
    background-color: #fff8f6;
}

.store-button {
    margin-left: 12px;
    background-color: #ff7a59;
    border: 0.5px solid #ff7a59;
    border-radius: 3px;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
}

.store-button:hover {
    background-color: #ff8f73;
}

.store-button:disabled, .search-button:disabled {
    pointer-events: none;
    color: #FFF;
    background-color: #AAAAAA;
    border: 0.5px solid #AAAAAA;
    opacity: 0.7;
}

.filter-object-split {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.margin-right-20 {
    margin-right: 20px;
}
