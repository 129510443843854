body {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
}

table, th, td {
    border: 1px solid black;
}

.navbar-container {
    margin: 0;
    padding: 10px;
    overflow: hidden;
    border: 1px solid #2F404F;
    background-color: #2F404F;
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 300;
}

.content {
    overflow-x: scroll;
    padding: 10px;
}