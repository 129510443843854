.contacts-table .placeholder-content_item1 {
    max-width: 14px;
}
.contacts-table .placeholder-content_item2 {
    max-width: 14px;
}
.contacts-table .placeholder-content_item3 {
    max-width: 200px;
}
.contacts-table .placeholder-content_item4 {
    max-width: 80px;
}
.contacts-table .placeholder-content_item5 {
    max-width: 140px;
}
.contacts-table .placeholder-content_item6 {
    max-width: 80px;
}
.contacts-table .placeholder-content_item7 {
    max-width: 100px;
}
.contacts-table .placeholder-content_item8 {
    max-width: 14px;
}

.contacts-table .header1 {
    width: 6%;
}
.contacts-table .header2 {
    width: 6%;
}
.contacts-table .header3 {
    width: 16%;
}
.contacts-table .header4 {
    width: 16%;
}
.contacts-table .header5 {
    width: 18%;
}
.contacts-table .header6 {
    width: 14%;
}
.contacts-table .header7 {
    width: 10%;
}
.contacts-table .header8 {
    width: 14%;
}